<!-- Remesas de Cobros / Pagos -->
<!-- Remesas de Cobros / Pagos -->

<template>
  <div class="cobrosPagosRemesasF" style="display:flex;justify-content:center" v-if="loaded">

    <dualTemplate
      :dualTipo="!storeRaiz? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">

          <v-sheet style="width:850px;justify-content:center" v-if="!viewMtoOnly" :elevation="4">

            <!-- Cabecera -->
            <div class="cabecera" v-if="!viewMtoOnly" >
              <baseHeader
                :cfg="$cfe.headers.finder"
                :titulo='stIni.titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <!-- Contenido -->
            <div class="contenedor">
              <div class="conflex">
                <div class="columna">

                  <baseGridMD
                    :store="storeName"
                    :headers="headerGrid"
                    :items-per-page=-1
                    dense
                    show-select
                    fixed-header
                    hide-default-footer
                    height="400px"
                    @onEvent="execAccion">

                      <!-- Slot botonera Top -->
                      <template v-slot:gridTop="{}">

                        <div style="display:flex;justify-content:space-between;width:100%">

                          <!-- Botonera Extra (Ver Expediente, Documentos, Comunicados, ...) -->
                          <baseBtraExtra
                            :permExtra="permExtra"
                            :modulo="btExtCfg"
                            @onEvent="execAccion">
                          </baseBtraExtra>

                          <!-- Filtro de búsqueda -->
                          <div style="width:40%;margin-left:10px">
                            <uiText
                              v-model="ct.txt[2]"
                              :label="ct.txt[1]"
                              appendIcon="mdi-magnify"
                              @clickIcon="filtrar"
                              @Enter="filtrar">
                            </uiText>
                          </div>

                        </div>
                      </template>

                      <template v-slot:cta="{ item }">
                        <div :class="item.dev_fh>''?'red--text':'blue--text'" > {{ item.cta }}</div>
                      </template>

                  </baseGridMD>

                  <div class="contenedor">
                    <div class="cab">DATOS REMESA</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex" style="padding-top:10px">
                        <uiText
                          style="width:26%"
                          v-model="getFhRemesa"
                          label="Fecha"
                          disabled>
                        </uiText>

                        <uiText
                          style="width:11%"
                          v-model="ct.ctas[2]"
                          label="Ctas"
                          disabled>
                        </uiText>

                        <uiText
                          style="width:11%"
                          v-model="ct.fras[2]"
                          label="Fras"
                          disabled>
                        </uiText>

                        <uiText
                          style="width:19%"
                          v-model="ct.tot[2]"
                          format="money"
                          label="Total"
                          disabled>
                        </uiText>

                        <uiText
                          style="width:15%"
                          v-model="ct.asi[2]"
                          label="Asiento"
                          disabled>
                        </uiText>

                        <uiText
                          style="width:15%"
                          v-model="getUsu"
                          label="Ope"
                          disabled>
                        </uiText>

                        <hr>

                        <uiText
                          style="width:30%"
                          v-model="getFhEmail"
                          label="Email"
                          disabled>
                        </uiText>

                        <uiText
                          style="width:30%"
                          v-model="ct.getFhDevolucion"
                          label="* Devolución"
                          disabled>
                        </uiText>

                      </div>
                    </v-sheet>
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </template>
    </dualTemplate>


    <!-- Componentes virtuales  -->
    <component
      :is="componenteDinamico"
      syncUpdate
      :syncDisparo="syncDisparoSend"
      :syncAccion="syncAccionSend"
      :storeRaiz="storeName"
      :readOnly="readOnly"
      :viewMtoOnly="viewMtoOnly"
      :recordsFras="getFras"
      @onVerRemesa="getRemesa"
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>

  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import dualTemplate from "@/components/dualTemplate";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import cobrosPagosUltimasRemesas from "@/components/cobrosPagosUltimasRemesas";
  import cobrosPagosFrasRemesa from "@/components/cobrosPagosFrasRemesa";


  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, dualTemplate, baseBtraExtra, cobrosPagosUltimasRemesas, cobrosPagosFrasRemesa },
    props: {},

    data() {
      return {

        stIni: {
          api: "cobrosPagosRemesasF",
          titulo:"·Remesas - Cobros/Pagos",
          name: "cobrosPagosRemesasF",
          mView: '',
          pView:[],
          selection:'both' // first, preserve, both
        },

        remesa:0,
        tipo:0,
        itemsCC: [],
        itemsTipos: []

      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Cuenta", value: "cta", witdh: "70%", slot:true },
            { text: "Fras", value: "fras", witdh: "5%" },
            { text: "Total", value: "tot", witdh: "25%" }
          ]}
        ];

        this.headerGrid= this.headerSet();

        // botones extra
        this.btExtCfg.btnsAccion= [
          { accion: "ultimasRemesas", icono: "mdi-format-list-bulleted", title:'Lista Últimas Remesas' },
          { accion: "frasRemesa", icono: "mdi-receipt", title:'Facturas Remesa' },
          { accion: "eliminarPago", icono: "mdi-delete", title:'Eliminar Pago' },
          { accion: "email", icono: "mdi-email-outline", title:'Envio de email a la cuenta seleccionada' },
          { accion: "informe", icono: "mdi-file-chart", title:'Informe Listado Remesa' },
          { accion: "remesaEnviar", icono: "mdi-alpha-r-circle-outline", title:'Generar Remesa y envio Email' },
          { accion: "emailAll", icono: "mdi-email-multiple-outline", title:'Envio de email a todas las cuentas' },
          { accion: "conta", icono: "mdi-alpha-c-circle", title:'Contabilizar Remesa' },
          { accion: "devolucion", icono: "mdi-alpha-d-circle", title:'Devolución Pago/Facturas' },
        ];

        // obtengo datos de GRID
        this.autoLoad= 'buscar';


        // items adicionales
        // array de cuentas bancarias
        this.itemsCC= [];

        // array de tipos de operaciones
        this.itemsTipos= []
      },


      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'get', { rem:0 }, "remesas"];
        this.apiArgs.pagos= ["", 'pagos', { fnt:'set', idP:0 }, "facturas"];
        this.apiArgs.email= ["", 'email', { rem:0, idP:0 }, "remesas"];
        this.apiArgs.informe= ["", 'listado', { rem:0, env:1 }, "remesas"];
        this.apiArgs.remesaBanco= ["", 'remesaBanco', { rem:0 }, "remesas"];
        this.apiArgs.conta= ["", 'conta', { rem:0 }, "remesas"];
        this.apiArgs.devolucion= ["", 'pagos', { fnt:'devo', id:0 }, "facturas"];
      },


      //
      async stRecordsGet({ filtro, busqueda, origen }) {
        let param = { apiArg: this.apiArgs.get, args: { rem:this.remesa, ctrls: filtro, accion:busqueda } };
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore, origen });
      },


      //
      recordsGetAfter() {
        this.calcularTotales();
        this.botonerasSet();
      },


      //
      calcularTotales() {

        // inicializa totales
        this.$set(this.ct.fras, 2, 0);
        this.$set(this.ct.ctas, 2, 0);
        this.$set(this.ct.tot, 2, 0);

        // recorre remesas para calcular totales
        this.records.map(elem => {
          this.$set(this.ct.fras, 2, Number(this.ct.fras[2]) + Number(elem.fras));
          this.$set(this.ct.ctas, 2, Number(this.ct.ctas[2]) + 1);
          this.$set(this.ct.tot, 2, Number(this.ct.tot[2]) + Number(elem.tot));
        });

        if (!this.records.length) return;

        // actualiza campos
        this.$set(this.ct.asi, 2, this.records[0].conta_asi);
        this.$set(this.ct.fh, 2, this.records[0].fh);

        this.remesa= this.records[0].rem;
        this.tipo=this.records[0].tipo_cta;
        this.stIni.titulo= 'Remesa ' + this.remesa;
      },


      // configuración botonera
      botonerasSet() {
        if (!this.ct) return;

        let NS=(!this.syncRecord || !this.syncRecord.id || this.syncRecord.id==0);

        this.btnSet(this.btExtCfg, "frasRemesa", { disabled: NS });
        this.btnSet(this.btExtCfg, "eliminarPago", { disabled: true });
        this.btnSet(this.btExtCfg, "email",{ disabled: NS });
        this.btnSet(this.btExtCfg, "conta", { disabled: this.ct.asi[2]=='0'? false : true });
        this.btnSet(this.btExtCfg, "devolucion",{ disabled: this.ct.asi[2]=='0' || NS || this.syncRecord.tipo_cta=='9'? true : false });
      },


      // carga el componente Últimas Remesas
      ultimasRemesas() {
        this.componenteDinamico= 'cobrosPagosUltimasRemesas';
      },


      // carga el componente Facturas Remesa
      frasRemesa() {
        this.componenteDinamico= 'cobrosPagosFrasRemesa';
      },


      // guarda remesa recibida y se carga
      getRemesa(evt) {
        this.remesa= evt.rem;
        this.componenteDinamico= null;
        this.refrescar();
      },


      // listado facturas remesa seleccionada
      async informe() {
        let param = { apiArg: this.apiArgs.informe, args: { rem:this.remesa } };
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
      },


      // envio de email a la cuenta seleccionada
      email() {
        if (this.tipo=='9') { this.$root.$alert.open('Es Pago de Liquidación de Apds.<br>Debes enviarla desde Liquidaciones de APDS', 'info'); return; }
        console.log('syncRecord: ', this.syncRecord);
        if (!Object.keys(this.syncRecord).length) {
          this.$root.$alert.open('Debe seleccionar una cuenta', 'info');
          return;
        }

        this.$root.$alert.open('Enviamos eMail Orden de Pago?<br>Cuenta: '
                               + this.syncRecord.cta + '<br>Facturas: ' + this.syncRecord.fras
                               + '<br>Importe: ' + this.syncRecord.tot, 'confirm')
        .then(r=> { if (r) this.envioEmail(); });
      },

      async envioEmail() {
        let param = { apiArg: this.apiArgs.email, args: { idP:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Email enviado correctamente', 'success', 1800);
      },


      // envio de email a todas las cuentas
      async emailAll() {
        if (this.tipo==9) {
          this.$root.$alert.open('Son Pagos de Liquidaciones de Apds.<br>Debes enviarla desde Liquidaciones de APDS', 'info'); return;
        }

        this.$root.$alert.open('Enviamos eMail Ordenes de Pago de Remesa? ' + this.remesa, 'confirm')
          .then(r=> { if (r) this.envioEmailAll(); });
      },

      async envioEmailAll() {
        let param = { apiArg: this.apiArgs.email, args: { rem:this.remesa }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Emails enviados', 'success');
      },


      //
      remesaEnviar() {
        this.$root.$alert.open('Generamos/Enviamos REMESA ' + this.remesa, 'confirm')
        .then(r=> { if (r) this.remesaEnviarContinue(); });
      },

      async remesaEnviarContinue() {
        let param = { apiArg: this.apiArgs.remesaBanco, args: { rem:this.remesa }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        //num,tot,err,errores
        let res=apiResult.r[0];

        this.$root.$alert.open('Remesa <b>' + this.remesa + '</b> generada (email).<br>' +
          '* Transferencias: <b>' + res[0] + '</b> Importe: <b>' + res[1] + '</b><br>' +
          '* Errores: <b>' + res[2] + '</b>' + (res[2]?'<hr>' + res[3] : '') + '<br>'
          , 'success');
      },


      //
      conta() {
        this.$root.$alert
        .open("¿CONTABILIZAMOS / LIQUIDAMOS REMESA " + this.remesa + "?", "confirm")
        .then(r => { if (r) this.contaContinue(); });
      },

      async contaContinue() {
        let param = { apiArg: this.apiArgs.remesaBanco, args: { rem:this.remesa }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Remesa Contabilizada. Asiento nº <b>' + apiResult.r[0] + '</b>', 'success');

        // recarga remesa
        this.refrescar();
      },


      // borro el pago seleccionado
      eliminarPago() {
        this.$root.$alert.open('¿Desea ELIMINAR el pago ?' + '<br>Se elimina de la REMESA y se actualiza la/s FACTURA/S <br>' +
          (this.selected[0].conta_asi>0 ? 'Ya esta contabilizado pero no enviado por lo que recontabilizara la remesa' : '')
          , 'confirm')
        .then(r=> { if (r) this.eliminarPagoContinue(); });
      },

      async eliminarPagoContinue() {
        let param = { apiArg: this.apiArgs.pagos, args: { idP:-this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // recargo remesa
        this.refrescar();

        //
        this.$emit('reloadCuentas');
      },


      devolucion() {
        let P= this.syncRecord;
        if (P.dev_fh) {
          this.$root.$alert.open('Esta Pago Ya ha sido Devuelto <b>' + P.dev_fh + '</b>', 'error');
          return;
        }

        this.$root.$alert.open("¿ DEVOLUCION PAGO/FACTURAS ?<hr><br>" +
          "Cuenta:<b> " + P.cta + "</b><br>" +
          "Facturas: <b> " + P.fras + '</b><br>' +
          "Importe: <b> " + P.tot + '</b><br><br>'
          ,"confirm")
        .then(r => { if (r) this.devolucionContinue(); });
      },

      async devolucionContinue() {
        let param = { apiArg: this.apiArgs.pagos, args: { id:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // idD, asi
        let r=apiResult.r;
        this.$root.$alert.open('Devolución Generada Asiento nº <b>' + r[1] + '</b>', 'success');

        // recarga remesa
        this.refrescar();
      },


      // filtrar registros localmente
      // recalcula totales
      async filtrar() {
        await this.stFiltrar(this.filtrarCta, {});
        this.calcularTotales();
      },

      // filtrar por tipo de documento
      // envíamos esta función desde 'filtrar', donde se ejecuta y actualiza los records del Grid
      filtrarCta(records) {
        let txt= this.ct.txt[2];

        return records.filter(elem=> {
          return (
              elem.cta.toLowerCase().includes(txt.toLowerCase())
            )
        });

      },


      // recarga GRID
      async refrescar() {
        this.storeUpdate('refresh');
      },


    },


    computed: {

      // devuelve schema particular
      sch() {
        return {
          pte: [ 'pte', 'Pendiente', "" ],
          fh: [ 'fh', 'Fecha', "" ],
          ctas: [ 'ctas', 'Cuentas', "" ],
          fras: [ 'fras', 'Facturas', "" ],
          tot: [ 'tot', 'Importe', "" ],
          asi: [ 'asi', 'Asiento', "" ],
          txt: [ 'txt', 'Búsqueda (Cuenta)', "" ]
        }
      },


      // devuelve la fecha de la remesa
      getFhRemesa() {
        return this.$options.filters['fecha'](this.ct.fh[2]);
      },


      // devuelve usuario creación remesa
      getUsu() {
        return Object.keys(this.syncRecord).length && this.syncRecord.conta_usu!=''? this.syncRecord.conta_usu : '';
      },


      // devuelve fecha envio email remesa
      getFhEmail() {
        return Object.keys(this.syncRecord).length && this.syncRecord.email_fh!=''? this.$options.filters['fechahr'](this.syncRecord.email_fh) : '';
      },


      // devuelve fecha devolución remesa
      getFhDevolucion() {
        return Object.keys(this.syncRecord).length && this.syncRecord.dev_fh!=''? this.$options.filters['fechahr'](this.syncRecord.dev_fh) : '';
      },


      // array de facturas de la remesa
      getFras() {
        if (!Object.keys(this.syncRecord).length) return [];
        return this.syncRecord.facturas;
      },

    },


    watch: {
      syncRecord() {
        this.botonerasSet();
      }
    }

  };
</script>
